@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;

.htmx-indicator {
  display: none;
}

.htmx-request .htmx-indicator {
  display: inline;
}

.htmx-request.htmx-indicator {
  display: inline;
}
[x-cloak] { display: none !important; }
//
//.sidebar-folded:not(.open-sidebar-folded) .sidebar .sidebar-body .sidebar-footer{
//  visibility: hidden;
//  opacity: 0;
//}
//p {
//  margin-bottom: 20px
//}
//
//.keystrokes {
//  border-radius: 15px;
//  font-family: "Roboto", Helvetica, sans-serif;
//  background-color: rgba(169, 182, 189, .08);
//  border: 1px solid #ccc;
//  color: #333
//}
